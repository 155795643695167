.about {
  flex-direction: column;
  margin-top: 3em;
}
.about__name {
  color: var(--clr-primary);
  transition: background-size 0.3s ease, color 0.3s ease;
  background-size: 200% 200%; /* Set it to 200% initially to hide the pattern */
  display: inline-block;
  
  /* Define the gradient pattern for the light theme here, but it's hidden by the large background-size */
  background-image: linear-gradient(transparent, transparent),
                    repeating-linear-gradient(
                      45deg,
                      #e0b0b0,
                      #e0b0b0 10px,
                      var(--clr-primary) 10px,
                      var(--clr-primary) 20px
                    );
  -webkit-background-clip: text; /* For webkit browsers */
  background-clip: text;
}

body.dark .about__name {
  /* Define the gradient pattern for the dark theme */
  background-image: linear-gradient(transparent, transparent),
                    repeating-linear-gradient(
                      45deg,
                      #e05252,
                      #e05252 10px,
                      var(--clr-primary) 10px,
                      var(--clr-primary) 20px
                    );
}

body.light .about__name:hover, body.dark .about__name:hover {
  color: transparent; /* To show the background pattern */
  background-size: 100% 100%; /* This makes the pattern visible */
}
.about > :nth-child(2) {
  margin-top: 1.2em;
  font-size: 2rem;
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

.about__desc {
  font-size: 1rem;
  max-width: 70%;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
    justify-content: center;
    align-items: center;
  }
  .about > :nth-child(2) {
    font-size: 1.5rem;
  }
}
