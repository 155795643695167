@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
* {
  margin: 0px;
  padding: 0px;
  border: 0;
  outline: 0;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4 {
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.3rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

.nav__list {
  margin-right: 1.5em;
  display: flex;
}
.nav__list-item {
  margin-left: 1.5em;
}
.app .nav__hamburger {
  display: none;
}
.nav__theme {
  margin-top: 0.4em;
}

@media (max-width: 600px) {
  .nav__list {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .nav__list-item {
    margin: 0.5em 0;
  }

  .app .nav__hamburger {
    display: flex;
    z-index: 2;
    margin-left: 0.8em;
  }
}

.header {
  height: 8em;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .header {
    height: 6em;
  }
}

.app {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
}

body.light {
  --clr-bg: #f7f3e9; /* Soft beige */
  --clr-bg-alt: #f5eec7; /* Lighter beige */
  --clr-fg: #353535; /* Dark gray for text */
  --clr-fg-alt: #646464; /* Lighter gray for alternative text */
  --clr-primary: #d4a5a5; /* Pastel rose for primary actions/highlights */
  --shadow: rgba(212, 165, 165, 0.05) 0px 4px 12px, rgba(53, 53, 53, 0.08) 0px 7px 29px 0px; 
}

body.dark {
  --clr-bg: #1e1f26; /* Deep space blue */
  --clr-bg-alt: #2a2c34; /* Slightly lighter space blue */
  --clr-fg: #f4f4f6; /* Near white for text */
  --clr-fg-alt: #b1b1b3; /* Light gray for alternative text */
  --clr-primary: #ff6363; /* Vibrant coral red for primary actions/highlights */
  --shadow: rgba(255, 99, 99, 0.05) 0px 4px 12px, rgba(30, 31, 38, 0.25) 0px 7px 29px 0px; 
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

.section {
  margin-top: 5em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.center {
  display: flex;
  align-items: center;
}

.link {
  color: var(--clr-primary);
  padding: 0 0 0.3em 0;
  position: relative;
}

.link:hover {
  color: var(--clr-primary);
}

.link::before {
  content: "";
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
  width: 100%;
}

.link--nav {
  color: var(--clr-fg);
  font-weight: 500;
}

.link--icon {
  color: var(--clr-fg);
}

.btn {
  display: block;
  cursor: pointer;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.btn--outline {
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn--outline:hover,
.btn--outline:focus {
  color: var(--clr-bg);
}

.btn--outline:before {
  content: "";
  position: absolute;
  background-color: var(--clr-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn--outline:hover:before,
.btn--outline:focus:before {
  right: 0;
}

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.btn--plain:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.btn--icon {
  padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
  color: var(--clr-primary);
}

.btn--icon:active {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

@media (max-width: 600px) {
  .section {
    margin-top: 4em;
  }
}

svg rect[fill="#216e39"] {
  fill: #ff6363;  /* Vibrant coral red */
}

svg rect[fill="#30a14e"] {
  fill: #ff8787;  /* Lighter shade of coral red */
}

svg rect[fill="#40c463"] {
  fill: #ffaaaa;  /* Even lighter shade of coral red */
}

svg rect[fill="#9be9a8"] {
  fill: #ffdcdc;  /* Very light coral red */
}

body.light svg rect[fill="#ebedf0"] {
  fill: #f7f3e9;  /* Soft beige from the light theme */
}

body.dark svg rect[fill="#ebedf0"] {
  fill: #1e1f26;  /* Deep space blue from the dark theme */
}

::-webkit-scrollbar {
  width: 12px;  
}

::-webkit-scrollbar-track {
  background: var(--clr-bg); 
}

body.light::-webkit-scrollbar-thumb {
  background: repeating-linear-gradient(
    45deg,
    #e0b0b0,
    #e0b0b0 10px,
    var(--clr-primary) 10px,
    var(--clr-primary) 20px
  );
  border-radius: 6px;
}

body.light::-webkit-scrollbar-thumb:hover {
  background: repeating-linear-gradient(
    45deg,
    #e0b0b0,
    #e0b0b0 5px,
    var(--clr-primary) 5px,
    var(--clr-primary) 10px
  );
}

body.dark::-webkit-scrollbar-thumb {
  background: repeating-linear-gradient(
    45deg,
    #e05252,
    #e05252 10px,
    var(--clr-primary) 10px,
    var(--clr-primary) 20px
  );
  border-radius: 6px;
}

body.dark::-webkit-scrollbar-thumb:hover {
  background: repeating-linear-gradient(
    45deg,
    #e05252,
    #e05252 5px,
    var(--clr-primary) 5px,
    var(--clr-primary) 10px
  );
}

::selection {
  background-color: var(--clr-primary);
  color: var(--clr-bg);
}

::-moz-selection {
  background-color: var(--clr-primary);
  color: var(--clr-bg);
}

img {
  -webkit-user-select: none;
          user-select: none;
}

.about {
  flex-direction: column;
  margin-top: 3em;
}
.about__name {
  color: var(--clr-primary);
  transition: background-size 0.3s ease, color 0.3s ease;
  background-size: 200% 200%; /* Set it to 200% initially to hide the pattern */
  display: inline-block;
  
  /* Define the gradient pattern for the light theme here, but it's hidden by the large background-size */
  background-image: linear-gradient(transparent, transparent),
                    repeating-linear-gradient(
                      45deg,
                      #e0b0b0,
                      #e0b0b0 10px,
                      var(--clr-primary) 10px,
                      var(--clr-primary) 20px
                    );
  -webkit-background-clip: text; /* For webkit browsers */
  background-clip: text;
}

body.dark .about__name {
  /* Define the gradient pattern for the dark theme */
  background-image: linear-gradient(transparent, transparent),
                    repeating-linear-gradient(
                      45deg,
                      #e05252,
                      #e05252 10px,
                      var(--clr-primary) 10px,
                      var(--clr-primary) 20px
                    );
}

body.light .about__name:hover, body.dark .about__name:hover {
  color: transparent; /* To show the background pattern */
  background-size: 100% 100%; /* This makes the pattern visible */
}
.about > :nth-child(2) {
  margin-top: 1.2em;
  font-size: 2rem;
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

.about__desc {
  font-size: 1rem;
  max-width: 70%;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
    justify-content: center;
    align-items: center;
  }
  .about > :nth-child(2) {
    font-size: 1.5rem;
  }
}

.introduction {
  width: 100%;
  display: flex;
  margin-top: 4em;
  margin-bottom: 4em;
}

.different {
  color: var(--clr-primary);
}

.introduction_logocontainer {
  flex-basis: 45%;
  display: flex;
  justify-content: center;
}

.introduction_logocontainer > img {
  width: 55%;
  height: 100%;
  border-radius: 3px;
}

.introduction_datacontainer {
  flex-basis: 55%;
}

.introduction_datacontainer > h4:nth-child(1) {
  margin-top: 0;
}

.introduction_datacontainer > h4:nth-child(2) {
  margin-top: 1.4em;
  line-height: 1.4em;
}

.introduction_datacontainer > h4 {
  font-size: 1.1em;
  margin-top: 0.5em;
}

.icons {
  position: relative;
  top: 5px;
  color: var(--clr-fg-alt);
  margin-right: 0.5em;
}

@media (max-width: 600px) {
  .introduction {
    flex-direction: column;
    margin-top: 2em;
  }
  .introduction_logocontainer {
    margin-bottom: 3em;
    flex-basis: 30%;
  }
  .introduction_logocontainer > img {
    width: 60%;
    height: 100%;
    border-radius: 20px;
  }
  .introduction_datacontainer {
    padding-left: 10%;
    padding-right: 10%;
    flex-basis: 70%;
  }
}

.main {
  margin-top: 4em;
}

.techsection {
  width: 100%;
  text-align: center;
  background-color: var(--clr-bg);
  /* Removed fixed height */
}

.techsection::after {
    content: "";
    display: table;
    clear: both;
}

.techsection > div {
  width: 11%;
  height: 16vh;
  max-height: 140px;
  box-shadow: var(--shadow);
  z-index: 1;
  border-radius: 20px;
  margin: 2.5%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease 0s;
}
.techsection > div:hover {
  background-color: var(--clr-bg);
  overflow: hidden;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: 1px solid var(--clr-primary);
}

.techsection > div > svg {
  font-size: 8vh;
  color: var(--clr-primary);
}

.techsection > div > h5 {
  position: relative;
  top: 8px;
}

@media (max-width: 600px) {
  .techsection > div {
    width: 40%;
    margin-right: 5%;
    margin-bottom: 2%;
    height: 18vh;
  }
}

.projects_container {
  width: 100%;
  margin: 0 auto;
}

.project {
  width: 100%;
  /* height: 70vh; */
  margin: 6% 0% 6% 0%;
  box-shadow: var(--shadow);
  display: flex;
  border-radius: 30px;
  transition: all 0.5s ease 0s;
}
.project:hover {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.project_videocontainer {
  flex-basis: 65%;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project_videocontainer > div {
  width: 90%;
  height: 90%;
  border: 10px solid var(--clr-primary);
  border-radius: 30px;
}
.project_videocontainer > div > img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.project_information {
  flex-basis: 35%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0% 3% 4% 3%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.project_information > h2 {
  margin-top: 1em;
  color: var(--clr-primary);
}
.project_information > p {
  margin-top: 1em;
  text-align: center;
}
.project_information > div:nth-child(3) {
  margin-top: 0.5em;
  display: flex;
  justify-content: space-evenly;
}

.project_information > div:nth-child(3) > svg {
  font-size: 3em;
  margin: 2% 3% 2% 3%;
  color: var(--clr-primary);
  transition: all 0.2s ease-in-out;
}

.project_information > div:nth-child(3) > svg:hover {
  font-size: 3.3em;
}

.project_information > div:nth-child(4) {
  margin-top: 0.6em;
  display: flex;
}
.onbt {
  margin: 0 1vw 0 1vw;
}
.project_information > h3 {
  margin-top: 1.6em;
  color: var(--clr-primary);
}
.project_information::-webkit-scrollbar {
  display: none;
}

@media (max-width: 600px) {
  .project {
    flex-direction: column;
    /* height: 100vh; */
  }
  .project_videocontainer {
    flex-basis: 40%;
  }
  .project_information {
    flex-basis: 60%;
  }
}

.github-disclaimer {
  text-align: center;
  margin-top: 2em;
  font-style: italic;
  color: var(--clr-fg-alt);
}

.github-disclaimer a {
  color: var(--clr-primary);
  text-decoration: underline;
}

.contactcontainer {
  display: flex;
  justify-content: space-evenly;
  padding-top: 2em;
}

.contactcontainer svg {
  font-size: 3em;
  transition: all 0.5s ease-in-out 0s;
  color: var(--clr-primary);
}
.contactcontainer svg:hover {
  font-size: 4rem;
}
.linkedin:hover {
  color: #0a66c2;
}
.github:hover {
  color: black;
}
.email:hover {
  color: #d93025;
}
.twitter:hover {
  color: rgb(29, 155, 240);
}
.phone:hover {
  color: rgb(49, 236, 49);
}
@media (max-width: 600px) {
  .contactcontainer svg {
    font-size: 2em;
  }
  .contactcontainer svg:hover {
    font-size: 3rem;
  }
}

.footer {
  text-align: center;
  color: var(--clr-primary);
  padding-bottom: 2em;
}

.scroll-top {
  position: fixed;
  bottom: 2em;
  right: 1em;
  background-color: transparent;
}

@media (max-width: 900px) {
  .scroll-top {
    display: none;
  }
}

