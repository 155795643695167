@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
.app {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
}

body.light {
  --clr-bg: #f7f3e9; /* Soft beige */
  --clr-bg-alt: #f5eec7; /* Lighter beige */
  --clr-fg: #353535; /* Dark gray for text */
  --clr-fg-alt: #646464; /* Lighter gray for alternative text */
  --clr-primary: #d4a5a5; /* Pastel rose for primary actions/highlights */
  --shadow: rgba(212, 165, 165, 0.05) 0px 4px 12px, rgba(53, 53, 53, 0.08) 0px 7px 29px 0px; 
}

body.dark {
  --clr-bg: #1e1f26; /* Deep space blue */
  --clr-bg-alt: #2a2c34; /* Slightly lighter space blue */
  --clr-fg: #f4f4f6; /* Near white for text */
  --clr-fg-alt: #b1b1b3; /* Light gray for alternative text */
  --clr-primary: #ff6363; /* Vibrant coral red for primary actions/highlights */
  --shadow: rgba(255, 99, 99, 0.05) 0px 4px 12px, rgba(30, 31, 38, 0.25) 0px 7px 29px 0px; 
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

.section {
  margin-top: 5em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.center {
  display: flex;
  align-items: center;
}

.link {
  color: var(--clr-primary);
  padding: 0 0 0.3em 0;
  position: relative;
}

.link:hover {
  color: var(--clr-primary);
}

.link::before {
  content: "";
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
  width: 100%;
}

.link--nav {
  color: var(--clr-fg);
  font-weight: 500;
}

.link--icon {
  color: var(--clr-fg);
}

.btn {
  display: block;
  cursor: pointer;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  transition: transform 0.2s ease-in-out;
}

.btn--outline {
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn--outline:hover,
.btn--outline:focus {
  color: var(--clr-bg);
}

.btn--outline:before {
  content: "";
  position: absolute;
  background-color: var(--clr-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn--outline:hover:before,
.btn--outline:focus:before {
  right: 0;
}

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.btn--plain:hover {
  transform: translateY(-4px);
}

.btn--icon {
  padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
  color: var(--clr-primary);
}

.btn--icon:active {
  transform: translateY(-5px);
}

@media (max-width: 600px) {
  .section {
    margin-top: 4em;
  }
}

svg rect[fill="#216e39"] {
  fill: #ff6363;  /* Vibrant coral red */
}

svg rect[fill="#30a14e"] {
  fill: #ff8787;  /* Lighter shade of coral red */
}

svg rect[fill="#40c463"] {
  fill: #ffaaaa;  /* Even lighter shade of coral red */
}

svg rect[fill="#9be9a8"] {
  fill: #ffdcdc;  /* Very light coral red */
}

body.light svg rect[fill="#ebedf0"] {
  fill: #f7f3e9;  /* Soft beige from the light theme */
}

body.dark svg rect[fill="#ebedf0"] {
  fill: #1e1f26;  /* Deep space blue from the dark theme */
}

::-webkit-scrollbar {
  width: 12px;  
}

::-webkit-scrollbar-track {
  background: var(--clr-bg); 
}

body.light::-webkit-scrollbar-thumb {
  background: repeating-linear-gradient(
    45deg,
    #e0b0b0,
    #e0b0b0 10px,
    var(--clr-primary) 10px,
    var(--clr-primary) 20px
  );
  border-radius: 6px;
}

body.light::-webkit-scrollbar-thumb:hover {
  background: repeating-linear-gradient(
    45deg,
    #e0b0b0,
    #e0b0b0 5px,
    var(--clr-primary) 5px,
    var(--clr-primary) 10px
  );
}

body.dark::-webkit-scrollbar-thumb {
  background: repeating-linear-gradient(
    45deg,
    #e05252,
    #e05252 10px,
    var(--clr-primary) 10px,
    var(--clr-primary) 20px
  );
  border-radius: 6px;
}

body.dark::-webkit-scrollbar-thumb:hover {
  background: repeating-linear-gradient(
    45deg,
    #e05252,
    #e05252 5px,
    var(--clr-primary) 5px,
    var(--clr-primary) 10px
  );
}

::selection {
  background-color: var(--clr-primary);
  color: var(--clr-bg);
}

::-moz-selection {
  background-color: var(--clr-primary);
  color: var(--clr-bg);
}

img {
  user-select: none;
}
